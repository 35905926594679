import { UserResponse } from '../../types/users/user-response'
import { authFetch } from '../../common/networking/auth-fetch'
import { PutUpdateUserRequest } from '../../types/users/requests/put-update-user-request'
import { CreateReferralCodeRequest } from '../../types/users/requests/create-referral-code-request'
import { CategoryPreferenceResponse } from '../../types/preferances/responses/category-preference-response'
import { PutUpdatePreferencesRequest } from '../../types/users/requests/put-update-preferences-request'
import { CreateReferralCodeResponse } from '../../types/users/responses/create-referral-code-response'
import { AddonResponse } from '../../types/addons/responses/AddonResponse'
import { ChampionResponse } from '../../types/users/responses/champion-response'
import { PostChampionRequest } from '../../types/users/requests/post-champion-request'

export async function getMe(accessToken: any): Promise<UserResponse | null> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me`,
  )
  return await response.json()
}

export async function putUpdateMe(
  accessToken: any,
  request: PutUpdateUserRequest,
): Promise<Response> {
  const response = await authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me`,
    request,
  )
  return await response.json()
}

export async function postCreateReferralCode(
  accessToken: any,
  request: CreateReferralCodeRequest,
): Promise<CreateReferralCodeResponse> {
  const response = await authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/referral-codes`,
    request,
  )

  return await response.json()
}

export async function getMyPreferences(
  accessToken: any,
  gameKey: string,
): Promise<CategoryPreferenceResponse> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/categories/${gameKey}/preferences`,
  )
  return await response.json()
}

export async function putUpdateMyPreferences(
  accessToken: any,
  request: PutUpdatePreferencesRequest,
): Promise<CategoryPreferenceResponse> {
  const response = await authFetch(accessToken).put(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/categories/${request.gameKey}/preferences`,
    request,
  )
  return await response.json()
}

export async function getMyAddOns(accessToken: any): Promise<AddonResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/addons`,
  )
  return await response.json()
}

export async function getMyChampions(
  accessToken: any,
): Promise<ChampionResponse[]> {
  const response = await authFetch(accessToken).get(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/champions`,
  )
  return await response.json()
}

export async function postMyChampions(
  accessToken: any,
  request: PostChampionRequest,
): Promise<Response> {
  return await authFetch(accessToken).post(
    `${process.env.NEXT_PUBLIC_DEFAULT_BASE_API_URL}/users/me/champions`,
    request,
  )
}
