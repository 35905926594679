import { atom } from 'recoil'
import { CategoryResponse } from '../../types/categories/responses/category-response'
import { LeaderboardDataResponse } from '../../types/dashboard/leaderboard-data-response'
import dayjs from 'dayjs'

export const isOnlineState = atom<boolean>({
  key: 'isOnlineState',
  default: false,
})

export const shouldReconnectToDashboardState = atom<boolean | null>({
  key: 'shouldReconnectToDashboardState',
  default: null,
})

export const onlineStartTimeState = atom<dayjs.Dayjs | number>({
  key: 'onlineStartTimeState',
  default: dayjs(),
})

export const isToggleOnlineState = atom<boolean>({
  key: 'isToggleOnlineState',
  default: false,
})

export const isFromDiscordState = atom<boolean>({
  key: 'isFromDiscordState',
  default: false,
})

export const isOpenCustomModalState = atom<boolean>({
  key: 'isOpenCustomModalState',
  default: false,
})

export const isOpenPreferredRoleState = atom<boolean>({
  key: 'isOpenPreferredRoleState',
  default: false,
})

export const isOpenRegionState = atom<boolean>({
  key: 'isOpenRegionState',
  default: false,
})

export const isOpenRankState = atom<boolean>({
  key: 'isOpenRankState',
  default: false,
})

export const isOpenChampionState = atom<boolean>({
  key: 'isOpenChampionState',
  default: false,
})

export const isOpenInGameUserNameState = atom<boolean>({
  key: 'isOpenInGameUserNameState',
  default: false,
})

export const navigationState = atom<string>({
  key: 'navigationState dashboard',
  default: 'Dashboard',
})

export const selectedLeaderboardGameState = atom<CategoryResponse>({
  key: 'selectedLeaderboardGameState',
  default: {} as CategoryResponse,
})

export const userLeaderboardStatusState = atom<LeaderboardDataResponse | null>({
  key: 'userLeaderboardStatusState',
  default: null,
})

export const toggleOffOnlineState = atom<boolean>({
  key: 'toggleOffOnlineState',
  default: true,
})

export const isGoOnlineButtonEnableState = atom<boolean>({
  key: 'isGoOnlineButtonEnableState',
  default: true,
})

export const isNotificationEnableState = atom<boolean>({
  key: 'isNotificationEnableState',
  default: true,
})

export const isOpenUnblockNotificationModalState = atom<boolean>({
  key: 'isOpenUnblockNotificationState',
  default: false,
})

export const isOpenOPGGGetLinkState = atom<boolean>({
  key: 'isOpenOPGGGetLinkInstructionState',
  default: false,
})
